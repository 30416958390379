import React from 'react';

const BingoLink = () => {
  return (
    <div className="text-md my-4 mx-4">
      La aplicación para sorteo disponible en:<br/> <a href="https://bingo.parroquia.co/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 focus:outline-none">bingo.parroquia.co</a>
    </div>
  );
};

export default BingoLink;

import React from 'react';
import { CiShare2 } from "react-icons/ci";

const ShareButton = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        url: window.location.href
      })
      .then(() => console.log('URL compartida'))
      .catch((error) => console.error('Error al compartir:', error));
    } else {
      alert('Tu navegador no soporta la función de compartir.');
    }
  };

  return (
    <button onClick={handleShare} 
    className="fixed text-xs top-4 right-4 bg-gray-800 text-white px-2 py-2 rounded-full shadow-md hover:bg-gray-600 focus:outline-none flex flex-row items-center justify-center space-x-2">
      <CiShare2 size={25} />
    </button>
  );
};

export default ShareButton;

import React, { useState, useEffect } from 'react';
import './App.css';
import ShareButton from './ShareButton';
import BingoLink from './BingoLink';
import { GrPowerReset } from "react-icons/gr";

// Función para generar la tarjeta de bingo
const generateBingoCard = () => {
  const card = [];
  const columns = ['B', 'I', 'N', 'G', 'O'];
  columns.forEach((col, colIndex) => {
    const colNumbers = [];
    for (let i = 0; i < 5; i++) {
      let number;
      do {
        number = Math.floor(Math.random() * 15) + 1 + colIndex * 15;
      } while (colNumbers.includes(number));
      colNumbers.push(number);
    }
    card.push(colNumbers);
  });
  // Asignar el centro de la tabla como espacio libre
  card[2][2] = "Free";
  return card;
};

// Componente de la tarjeta de bingo
const BingoCard = ({ card, markedNumbers, toggleNumber }) => {
  return (
    <div className="flex flex-col items-center border p-4 rounded-lg shadow-sm bg-white m-4 md:m-0">
      <h1 className='mb-4 font-bold text-3xl'>Carton de Bingo</h1>
      <div className="flex flex-row">
        {['B', 'I', 'N', 'G', 'O'].map((col, colIndex) => (
          <div key={colIndex} className="flex flex-col">
            <div className="w-16 h-16 m-0.5 text-center bg-gray-800 text-gray-300 flex items-center justify-center font-bold text-2xl">{col}</div>
            {card[colIndex].map((number, rowIndex) => (
              <div
                key={rowIndex}
                className={`w-16 h-16 m-0.5 text-center flex items-center justify-center cursor-pointer ${markedNumbers.includes(number) || number === "Free" ? 'bg-green-600 rounded-full text-white' : 'bg-gray-200'}`}
                onClick={() => number !== "Free" && toggleNumber(number)}
              >
                {number}
              </div>
            ))}
          </div>
        ))}
      </div>
      <BingoLink />
    </div>
  );
};

// Componente principal de la aplicación
function App() {
  const [card, setCard] = useState(() => {
    const savedCard = localStorage.getItem('bingoCard');
    return savedCard ? JSON.parse(savedCard) : generateBingoCard();
  });

  const [markedNumbers, setMarkedNumbers] = useState(() => {
    const savedMarkedNumbers = localStorage.getItem('markedNumbers');
    return savedMarkedNumbers ? JSON.parse(savedMarkedNumbers) : ["Free"];
  });

  useEffect(() => {
    localStorage.setItem('bingoCard', JSON.stringify(card));
  }, [card]);

  useEffect(() => {
    localStorage.setItem('markedNumbers', JSON.stringify(markedNumbers));
  }, [markedNumbers]);

  const toggleNumber = (number) => {
    setMarkedNumbers(prevState =>
      prevState.includes(number)
        ? prevState.filter(n => n !== number)
        : [...prevState, number]
    );
  };

  const newGame = () => {
    const newCard = generateBingoCard();
    setCard(newCard);
    setMarkedNumbers(["Free"]);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <BingoCard card={card} markedNumbers={markedNumbers} toggleNumber={toggleNumber} />
      <button onClick={newGame} 
      className="mt-4 px-4 py-2 bg-gray-800 hover:bg-gray-600 text-white rounded-full flex flex-row items-center justify-center space-x-3">
        <GrPowerReset size={25}/> 
        <div>Nuevo Juego</div>
      </button>
      <ShareButton />
      <div className='text-xs mt-4'>
        Hecho con amor en <a href="https://cencomer.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 focus:outline-none focus:text-blue-700">Cencomer.com</a> para <a href="https://parroquia.co" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 focus:outline-none focus:text-blue-700">Parroquia.co</a>
      </div>
    </div>
  );
}

export default App;
